<template>
  <v-container fluid class="pa-0">
    <v-card flat tile>
      <v-tabs
        show-arrows
        grow
        background-color="transparent"
        color="primary"
        v-model="activeTab"
        data-v-step="flight_0"
      >
        <v-tab color="primary">
          <v-icon class="mr-2">mdi-airplane-takeoff</v-icon>
          {{ $t("flightinfo.departures") }}
        </v-tab>
        <v-tab color="primary">
          <v-icon class="mr-2">mdi-airplane-landing</v-icon>
          {{ $t("flightinfo.arrivals") }}
        </v-tab>
      </v-tabs>
      <v-container fluid>
        <!-- container wrappers needed to highlight entire select + v-textfield -->
        <v-container class="pa-0" data-v-step="flight_1">
          <v-select
            dense
            outlined
            v-if="selectedAirport"
            :disabled="airports.length <= 1"
            :items="airports"
            v-model="selectedAirport"
            single-line
            return-object
            prepend-inner-icon="mdi-airplane-off"
            item-text="name"
            hide-details
          ></v-select>
        </v-container>
        <v-container class="pa-0" data-v-step="flight_2">
          <v-text-field
            dense
            class="mt-2"
            outlined
            hide-details
            v-model="searchTerm"
            @keydown.native.enter="getFlights"
            :label="$t('flightinfo.enterSearchTerm')"
            :append-icon="
              searchTerm && searchTerm.length > 0 ? 'mdi-close' : ''
            "
            @click:append="(searchTerm = ''), (page = 1)"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-container>
      </v-container>
    </v-card>
    <v-skeleton-loader
      :loading="
        statusDepartures === 'fetching' || statusArrivals === 'fetching'
      "
      type="table"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">{{ $t("flightinfo.date") }}</th>
            <th class="text-left">
              {{ activeTab == 0 ? $t("flightinfo.to") : $t("flightinfo.from") }}
            </th>
            <th class="text-right">{{ $t("flightinfo.flight") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in activeTab == 0 ? departures : arrivals"
            :key="item.name"
          >
            <td>
              {{
                $helpers.formatDate(
                  convertDate2(item.scheduled_gate_time),
                  $config["flight_info_date_format" + $config.format24Or12]
                )
              }}
            </td>
            <td data-v-step="flight_4">
              {{ activeTab == 0 ? item.destination_city : item.origin_city }}
            </td>
            <td data-v-step="flight_5" class="text-right">
              <span>
                {{ item.flight_number }}
                <br />
              </span>
              <span
                :style="{
                  color:
                    item.status === 'Canceled' || item.status === 'Diverted'
                      ? 'red'
                      : 'green',
                }"
                >{{ item.status }}</span
              >
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-skeleton-loader>
    <!--<v-data-table
      :items="activeTab == 0 ? departures : arrivals"
      hide-actions
      :loading="
        activeTab === 0
          ? statusDepartures === 'fetching'
          : statusArrivals === 'fetching'
      "
    >
      <template slot="headers" slot-scope="props">
        <th class="text-left">{{ $t('flightinfo.date') }}</th>
        <th class="text-left">
          {{ activeTab == 0 ? $t('flightinfo.to') : $t('flightinfo.from') }}
        </th>
        <th class="text-right">{{ $t('flightinfo.flight') }}</th>
      </template>
      <template slot="no-data">
        <tr>
          <td colspan="100%" class="text-center">
            {{ $t('flightinfo.noFlightsAvailable') }}
          </td>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <tr>
          <td>
            {{
              $helpers.formatDate(
                convertDate2(props.item.scheduled_gate_time),
                $config['flight_info_date_format' + $config.format24Or12]
              )
            }}
          </td>
          <td>
            {{
              activeTab == 0
                ? props.item.destination_city
                : props.item.origin_city
            }}
          </td>
          <td class="text-right">
            <span>
              {{ props.item.flight_number }}
              <br />
            </span>
            <span
              :style="{
                color:
                  props.item.status === 'Canceled' ||
                  props.item.status === 'Diverted'
                    ? 'red'
                    : 'green'
              }"
              >{{ props.item.status }}</span
            >
          </td>
        </tr>
      </template>
    </v-data-table>-->
    <v-divider></v-divider>
    <div
      class="text-center my-3"
      v-if="(activeTab == 0 ? totalDeparturePages : totalArrivalPages) > 0"
    >
      <v-pagination
        v-if="activeTab == 0 ? totalDeparturePages > 1 : totalArrivalPages > 1"
        v-model="page"
        :length="activeTab == 0 ? totalDeparturePages : totalArrivalPages"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      activeTab: 0,
      pagination: {},
    };
  },
  methods: {
    ...mapActions("flightinfo", [
      "getAirports",
      "getDepartures",
      "getArrivals",
    ]),
    ...mapMutations("flightinfo", {
      setSelectedAirport: "SET_SELECTED_AIRPORT",
      setPage: "SET_PAGE",
      setSearchTerm: "SET_SEARCH_TERM",
    }),
    initData() {
      const { page = 1, q = undefined } = this.$route.query;
      this.setPage(page);
      this.setSearchTerm(q);
      this.getAirports();
    },
    getFlights() {
      this.getArrivals();
      this.getDepartures();
    },
    convertDate2(dateString) {
      var reggie = /(\d{4})-(\d{2})-(\d{2})(\w{1})(\d{2}):(\d{2}):(\d{2})/;
      var dateArray = reggie.exec(dateString);
      var dateObject = new Date(
        +dateArray[1],
        +dateArray[2] - 1, // month starts at 0!
        +dateArray[3],
        +dateArray[5],
        +dateArray[6],
        +dateArray[7]
      );

      return dateObject;
    },
  },
  computed: {
    ...mapState({
      airports: ({ flightinfo }) => flightinfo.airports,
      departures: ({ flightinfo }) => flightinfo.departures,
      arrivals: ({ flightinfo }) => flightinfo.arrivals,
      totalArrivalPages: ({ flightinfo }) => flightinfo.totalArrivalPages,
      totalDeparturePages: ({ flightinfo }) => flightinfo.totalDeparturePages,
      statusDepartures: ({ flightinfo }) => flightinfo.statusDepartures,
      statusArrivals: ({ flightinfo }) => flightinfo.statusArrivals,
    }),
    searchTerm: {
      get() {
        return this.$store.state.flightinfo.searchTerm;
      },
      set(val) {
        this.setSearchTerm(val);
      },
    },
    selectedAirport: {
      get() {
        return this.$store.state.flightinfo.selectedAirport;
      },
      set(val) {
        this.setSelectedAirport(val);
      },
    },
    page: {
      get() {
        return this.$store.state.flightinfo.page;
      },
      set(val) {
        this.setPage(val);
      },
    },
  },
  created() {
    this.initData();
  },
  watch: {
    "$route.query.airport"() {
      this.getFlights();
    },
    "$route.query.page"(val) {
      this.page = val;
      this.getFlights();
    },
    "$route.query.q"(val) {
      this.page = 1;
      this.searchTerm = val;
      this.getFlights();
    },
    activeTab() {
      this.setPage(1);
    },
  },
};
</script>
